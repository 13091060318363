import React from 'react'
import Line from '../../../assets/svg/line.svg'
import '../../../styles/custom/comment.css'
import { TopLeftBorderIcon, TopRightBorderIcon, BottomLeftBorderIcon, BottomRightBorderIcon } from '../../../assets/shape/border'

const Comment = ({ text }) => {
    return (
        <div className='flex flex-row items-center'>
            <div className='relative'>
                <div className='absolute w-[6px] h-[6px] bg-custom-orange rounded-full -bottom-1'></div>
                <img src={Line} alt='lien shape' className='ml-1' />
            </div>
            <div className='relative comment flex flex-row justify-center items-center -mt-12'>
                <div className='mr-[18px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">
                        <path d="M14 8L8.5 3L3 8" stroke="#FF5B04" strokeWidth="3" strokeLinecap="square" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none" className='-mt-1'>
                        <path d="M14 8L8.5 3L3 8" stroke="#FF5B04" strokeWidth="3" strokeLinecap="square" />
                    </svg>
                </div>
                <div className='text-white opacity-80 text-[14px] font-[400] leading-[120%] w-[140px]'>
                    {text}
                </div>
                <div className='absolute top-0 left-0'>
                    <TopLeftBorderIcon />
                </div>
                <div className='absolute top-0 right-0'>
                    <TopRightBorderIcon />
                </div>
                <div className='absolute bottom-0 left-0'>
                    <BottomLeftBorderIcon />
                </div>
                <div className='absolute bottom-0 right-0'>
                    <BottomRightBorderIcon />
                </div>
            </div>
        </div>
    )
}

export default Comment
