import { rule } from 'postcss';
import React from 'react'
import { useFormContext } from 'react-hook-form';

const Input = ({ placeholder, name, label, type }) => {
    const { register, formState: { errors } } = useFormContext();
    let rules = {
        required: `${label} is required`,
    };

    if (type === 'email') {
        rules = {
            ...rules,
            pattern: {
                value: /^[^@ ]+@[^@ ]+\.[^@ ]+$/,
                message: 'Email is not valid',
            },
        };
    }

    return (
        <div className='flex flex-col gap-2'>
            <div 
            className='input px-4 flex items-center text-white md:w-[382px] w-full rounded-md'
            style={{borderColor: errors[name] && '#C21313'}}
            >
                <input
                    name={name}
                    className='border-none w-full outline-none bg-transparent h-[45px]'
                    placeholder={placeholder}
                    {...register(name, rules)}
                />
            </div>
            {errors[name] && <p className='text-additional-error'>{errors[name].message}</p>}
        </div>

    )
}

export default Input
