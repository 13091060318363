import React, { useState } from 'react'
import { footerLinks } from '../../config/const'

const Uncheck = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <g clipPath="url(#clip0_6730_1078)">
                <rect x="0.5" width="20" height="20" rx="4" fill="#090B0A" />
                <rect x="0.5" width="20" height="20" rx="4" fill="url(#paint0_radial_6730_1078)" fillOpacity="0.1" />
                <rect x="1" y="0.5" width="19" height="19" rx="3.5" stroke="white" strokeOpacity="0.1" />
                <rect x="1" y="0.5" width="19" height="19" rx="3.5" stroke="url(#paint1_linear_6730_1078)" strokeOpacity="0.1" style={{ mixBlendMode: 'plus-darker' }} />
            </g>
            <defs>
                <radialGradient id="paint0_radial_6730_1078" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5 20) rotate(-90) scale(20 12.6118)">
                    <stop stopColor="#4D4D4D" />
                    <stop offset="1" stopColor="#4D4D4D" stopOpacity="0.376471" />
                </radialGradient>
                <linearGradient id="paint1_linear_6730_1078" x1="10.5" y1="-0.416667" x2="10.5" y2="20" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4D4D4D" stopOpacity="0" />
                    <stop offset="1" stopColor="#4A4A4A" />
                </linearGradient>
                <clipPath id="clip0_6730_1078">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

const Check = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <g clipPath="url(#clip0_6730_1078)">
                <rect x="0.5" width="20" height="20" rx="4" fill="#FF5B0405" />
                <rect x="0.5" width="20" height="20" rx="4" fill="url(#paint0_radial_6730_1078)" fillOpacity="0.1" />
                <rect x="1" y="0.5" width="19" height="19" rx="3.5" stroke="#FF5B04" strokeOpacity="0.8" />
                <rect x="1" y="0.5" width="19" height="19" rx="3.5" stroke="url(#paint1_linear_6730_1078)" strokeOpacity="0.1" style={{ mixBlendMode: 'plus-darker' }} />
                <path d="M6 10 l2 2 l6 -6" stroke="#FF5B04" strokeWidth="2" fill="none" />
            </g>
            <defs>
                <radialGradient id="paint0_radial_6730_1078" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5 20) rotate(-90) scale(20 12.6118)">
                    <stop stopColor="#4D4D4D" />
                    <stop offset="1" stopColor="#4D4D4D" stopOpacity="0.376471" />
                </radialGradient>
                <linearGradient id="paint1_linear_6730_1078" x1="10.5" y1="-0.416667" x2="10.5" y2="20" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4D4D4D" stopOpacity="0" />
                    <stop offset="1" stopColor="#4A4A4A" />
                </linearGradient>
                <clipPath id="clip0_6730_1078">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

const CheckBox = ({ checked, setChecked }) => {
    return (
        <div className='flex flex-row items-center gap-3 sm:w-full w-[95%]' onClick={() => setChecked(prev => !prev)}>
            {checked ? <Check /> : <Uncheck />}
            <p><span className='opacity-60'>I agree to the </span><a href={footerLinks[0].link} className='text-custom-orange underline'>Terms of Service</a><span className='opacity-60'> and </span><a href={footerLinks[0].link} className='text-custom-orange underline'>Privacy Policy</a></p>
        </div>
    )
}

export default CheckBox