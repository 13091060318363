export const TopLeftBorderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
      <path d="M1 5L1 1L5 1" stroke="#FF5B04" />
    </svg>
  )
}

export const TopRightBorderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
      <path d="M4 5L4 1L1.74846e-07 1" stroke="#FF5B04" />
    </svg>
  )
}

export const BottomLeftBorderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
      <path d="M1 0L1 4L5 4" stroke="#FF5B04" />
    </svg>
  )
}

export const BottomRightBorderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
      <path d="M4 0L4 4L1.74846e-07 4" stroke="#FF5B04" />
    </svg>
  )
}
