import React, { useState, useEffect } from 'react'
import Logo from '../assets/image/logo.png'
import { Link } from 'react-router-dom'
import { navItems } from '../config/const'
import { Button } from '../component/common/button'
import { useTranslation } from 'react-i18next'

const Header = () => {
    const { t, i18n } = useTranslation();
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const scrollHandler = () => {
            setScrolled(window.scrollY > 0);
        };

        window.addEventListener("scroll", scrollHandler);

        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    return (
        <header className={`fixed max-w-[1440px] min-w-[375px] lg:px-20 md:px-10 px-4 md:py-10 py-6 z-50 w-full duration-500 flex flex-row justify-between items-center ${scrolled ? 'backdrop-blur-md' : ''}`}>
            <div className='w-[138px] h-[24px]'>
                <img src={Logo} alt='logo' />
            </div>
            <nav className='font-[Nunito] md:flex hidden flex-row gap-8'>
                {navItems.map(({ label, link }, index) =>
                    <a href={link} key={label} className='text-white text-4 font-[500] line-normal'>
                        {t(label)}
                    </a>
                )}
            </nav>
            <Button className='button-secondary'>
                {t("header-button")}
            </Button>
        </header>
    )
}

export default Header
