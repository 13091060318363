import React from 'react'
import { ArrowIcon } from '../../assets/shape/arrow'

export const Button = ({ className, children, color, onClick }) => {
  return (
    <div className={`md:px-6 md:py-[11px] cursor-pointer duration-300 py-[7px] px-4 flex flex-row gap-[6px] relative ${className}`} onClick={onClick}>
      <div className='text-white font-[Nunito] md:text-[18px] text-[16px] font-[600] line-normal'>
        {children}
      </div>
      <div className='pb-2'>
        <ArrowIcon width='8' height='8' color={color ? color : 'white'} />
      </div>
    </div>
  )
}

export const GhostButton = ({ text }) => {
  return (
    <a href='' className='underline text-custom-orange text-[16px] font-[500] line-normal flex flex-row gap-[6px] items-center'>
      <span>{text}</span>
      <div className='pb-2'>
        <ArrowIcon width='8' height='8' color={'#ff5b04'} />
      </div>
    </a>
  )
}

export const SmallButton = ({ children }) => {
  return (
    <div className='sm-button'>
      {children}
    </div>
  )
}

export const ActiveButton = () => {
  return (
    <div className='active-button'>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g filter="url(#filter0_i_6209_222)">
          <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z" fill="#FF5B04" />
        </g>
        <defs>
          <filter id="filter0_i_6209_222" x="3.63574" y="5.97852" width="17.2706" height="12.3208" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="0.3" dy="0.3" />
            <feGaussianBlur stdDeviation="0.6" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.356863 0 0 0 0 0.0156863 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6209_222" />
          </filter>
        </defs>
      </svg>
    </div>
  )
}

export const DisableButton = () => {
  return (
    <div className='disable-button'>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g opacity="0.5">
          <g filter="url(#filter0_i_6209_228)">
            <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z" fill="white" />
            <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z" fill="url(#paint0_linear_6209_228)" fillOpacity="0.2" style={{ mixBlendMode: 'hard-light' }} />
          </g>
        </g>
        <defs>
          <filter id="filter0_i_6209_228" x="3.63574" y="5.97852" width="17.2706" height="12.3208" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="0.3" dy="0.3" />
            <feGaussianBlur stdDeviation="0.6" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.356863 0 0 0 0 0.0156863 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6209_228" />
          </filter>
          <linearGradient id="paint0_linear_6209_228" x1="12.121" y1="5.97852" x2="12.121" y2="17.9993" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF5B04" stopOpacity="0" />
            <stop offset="1" stopColor="#FF5B04" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}