import React from 'react'

const Bubble = ({ width, height }) => {
    return (
        <div className='bubble'>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 90 90" fill="none">
                <g opacity="0.6" filter="url(#filter0_b_6164_257)">
                    <circle cx="45" cy="45" r="45" fill="white" fillOpacity="0.01" />
                    <circle cx="45" cy="45" r="45" fill="url(#paint0_linear_6164_257)" fillOpacity="0.04" />
                    <circle cx="45" cy="45" r="45" fill="url(#paint1_radial_6164_257)" fillOpacity="0.15" />
                    <circle cx="45" cy="45" r="44.5" stroke="url(#paint2_linear_6164_257)" strokeOpacity="0.1" />
                </g>
                <defs>
                    <filter id="filter0_b_6164_257" x="-18" y="-18" width="126" height="126" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="9" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6164_257" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_6164_257" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_6164_257" x1="45" y1="0" x2="45" y2="90" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <radialGradient id="paint1_radial_6164_257" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(45 90) rotate(-90) scale(90)">
                        <stop stopColor="#ED5C15" />
                        <stop offset="1" stopOpacity="0" />
                    </radialGradient>
                    <linearGradient id="paint2_linear_6164_257" x1="45" y1="0" x2="45" y2="90" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default Bubble
