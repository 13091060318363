import React from 'react'
import HeroSection from './hero'
import AboutUs from './about'
import Pricing from './pricing'
import Access from './access'
import Crypto from './crypto'
import Security from './security'
import FAQ from './faq'
import Works from './works'

const Home = () => {
  return (
    <div>
      <HeroSection />
      <AboutUs />
      <Works />
      <Pricing />
      <Crypto />
      <Security />
      <FAQ />
      <Access />
    </div>
  )
}

export default Home
