import React from 'react'
import logo from '../../../assets/image/logo.png'
import { useTranslation } from 'react-i18next'
import { ActiveButton, Button } from '../../common/button'
import { access_link } from '../../../config/const'

const DownloadModal = ({ handleClose }) => {
    const { t } = useTranslation();

    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains('modal-container')) {
            handleClose();
        }
    };

    return (
        <div
            className='fixed modal-container w-full h-full'
            onClick={handleBackgroundClick} // Add the click handler here
        >
            <div className='relative md:left-1/2 top-1/2 md:-translate-x-1/2 -translate-y-1/2 modal-background md:w-[568px] md:h-[435px] w-auto h-auto mx-4 flex flex-col gap-9 pt-8 pb-10 md:px-14 px-5 items-center'>
                <div className='absolute right-9 top-6 cursor-pointer' onClick={handleClose}>
                    <span className='text-white opacity-40 hover:opacity-100 text-[25px]'>&times;</span>
                </div>
                <img src={logo} alt='logo' />
                <ActiveButton />
                <div className='flex flex-col gap-4 text-center items-center'>
                    <h3 className='md:w-[280px] w-[200px]'>{t("download-modal-header")}</h3>
                    <p className='w-full opacity-60'>{t("download-modal-description")}</p>
                </div>
                <a href={access_link}>
                    <Button className='button-primary' onClick={handleClose} >
                        {t("download-modal-button")}
                    </Button>
                </a>
            </div>
        </div>
    );
}

export default DownloadModal;
