import React from 'react'
import SubscribeForm from '../component/page/footer/subscribeForm';
import { useTranslation } from 'react-i18next'
import { navItems, footerIcons, footerLinks } from '../config/const'
import footerBg from '../assets/image/footer.png';
import Logo from '../assets/image/logo.png'

const Footer = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <footer className='md:block hidden relative max-w-[1440px] min-w-[375px] px-20 pb-[40px]'>
                <div className='absolute right-0 -z-10 bottom-0'>
                    <img src={footerBg} alt='footer' />
                </div>
                <div className='flex justify-between mt-[105px]'>
                    <img src={Logo} alt='logo' />
                    <div className='flex gap-8 items-center'>
                        {navItems.map((item, index) =>
                            <a href={item.link} key={`nav-${index}`} className='text-white font-[Nunito] text-[16px] font-[500] line-normal'>
                                {t(item.label)}
                            </a>
                        )}
                    </div>
                </div>
                <div className='flex mt-[22px] gap-4'>
                    {footerIcons.map((data, index) =>
                        <a href={data.link} key={`footer-icon-${index}`}>
                            <img src={data.icon} alt={data.label} />
                        </a>
                    )}
                </div>
                <div className='flex mt-[50px]'>
                    <SubscribeForm text={t('footer-subscribe')} />
                </div>
                <div className='flex justify-between mt-5'>
                    <h6>{t('footer-join')}</h6>
                    <div className='flex gap-4'>
                        {footerLinks.map((data, index) =>
                            <a href={data.link} key={`footer-link-${index}`} className='text-white text-[14px] font-[400] leading-[140%] opacity-30'>
                                {t(data.label)}
                            </a>
                        )}
                    </div>
                </div>
            </footer>
            <footer className='md:hidden block relative sm:px-10 px-4 py-10'>
                <div className='absolute right-0 top-0 -z-10'>
                    <img src={footerBg} alt='footer' />
                </div>
                <div className='inline-flex flex-col footer-grid gap-10 w-full'>
                    <div className='grid gap-6 opacity-80'>
                        <div className='grid grid-rows-2 grid-flow-col gap-6'>
                            {navItems.map((item, index) =>
                                <a href={item.link} key={`nav-${index}`} className='text-white font-[Nunito] text-[16px] font-[500] line-normal w-[105px]'>
                                    {t(item.label)}
                                </a>
                            )}
                        </div>
                        <div className='grid grid-cols-2 gap-6'>
                            {footerLinks.map((data, index) =>
                                <a href={data.link} key={`footer-link-${index}`} className='text-white text-[14px] font-[400] leading-[140%] w-[105px] opacity-80'>
                                    {t(data.label)}
                                </a>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-col gap-5 sm:w-[400px] w-full'>
                        <SubscribeForm text={t('footer-subscribe')} />
                        <p className='opacity-60'>{t('footer-join')}</p>
                    </div>
                    <div className='flex justify-between w-full'>
                        <div className='w-[138px]'>
                            <img src={Logo} alt='logo' />
                        </div>
                        <div className='flex gap-4 items-center'>
                            {footerIcons.map((data, index) =>
                                <a href={data.link} key={`footer-icon-${index}`}>
                                    <img src={data.icon} alt={data.label} />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
