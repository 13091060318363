import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { ActiveButton, DisableButton } from './button';

const ProgressBar = ({ steps, scrollYProgress }) => {
    const componentRef = useRef(null);
    
    return (
        <div className='relative'>
            <motion.div style={{ height: `${scrollYProgress + 250}px` }} className='overflow-hidden md:left-1/2 left-4 absolute'>
                <div className='z-10 flex flex-col items-center text-center justify-center'>
                    {Array.from({ length: steps }, (_, index) => (
                        <div key={`enable-process-part-${index}`}>
                            {index == 0 && <div className='progress-start-enable-line'></div>}
                            {index != 0 && <div key={index} className='progress-enable-line'></div>}
                            <ActiveButton />
                        </div>
                    ))}
                </div>
            </motion.div>
            <div ref={componentRef} className='absolute md:left-1/2 left-4 flex flex-col items-center text-center justify-center'>
                {Array.from({ length: steps }, (_, index) => (
                    <div key={`disable-process-part-${index}`}>
                        {index == 0 && <div className='progress-start-disable-line'></div>}
                        {index != 0 && <div key={index} className='progress-disable-line'></div>}
                        <DisableButton />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProgressBar;
