import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../component/common/button';
import Lock from '../assets/svg/lock.svg';
import LockPattern from '../assets/shape/lock-pattern.png';
import DotsPattern from '../assets/shape/dots.png';
import AccessModal from '../component/page/access/AccessModal'

const Security = () => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => {
        setOpenModal(true);
        document.body.style.overflow = 'hidden';
    }

    const handleClose = () => {
        setOpenModal(false);
        document.body.style.overflow = 'unset';
    }


    return (
        <section id='security' className='w-full overflow-hidden px-4 my-20'>
            <div className='relative mx-auto pb-[144px] flex flex-col aspect-square justify-center items-center'>
                <div className='flex flex-col gap-10 items-center z-50'>
                    <div className='flex flex-col gap-6 items-center text-center'>
                        <h2 className='sm:w-auto w-[220px]'>{t("security-title")}</h2>
                        <h6 className='md:w-[600px] sm:w-full w-[300px]'>{t("security-description")}</h6>
                    </div>
                    <Button className='button-secondary' onClick={handleOpen}>
                        {t("security-button")}
                    </Button>
                </div>
                <div className='relative -z-10'>
                    <img src={LockPattern} alt='lock pattern' className='absolute -z-10 opacity-40 top-[150px] sm:block hidden' />
                    <img src={LockPattern} alt='lock pattern' width='135px' height='157px' className='absolute -z-10 opacity-40 top-[150px] sm:hidden block' />
                    <div className='mt-[150px] relative'>
                        <img src={Lock} alt='lock' className='absolute -z-20 sm:block hidden' />
                        <img src={Lock} alt='lock' width='134px' height='157px' className='absolute -z-20 sm:hidden block' />
                        <div className='z-30 sm:block hidden'>
                            <svg width="195" height="230" viewBox="-1 -1 195 230" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="lockPath" d="M159.5 75.3332V75.8332H160H181.333C186.948 75.8332 191.5 80.385 191.5 85.9998V214C191.5 219.615 186.948 224.167 181.333 224.167H10.6667C5.05182 224.167 0.5 219.615 0.5 214V85.9998C0.5 80.385 5.05182 75.8332 10.6667 75.8332H32H32.5V75.3332V64.6665C32.5 29.5964 60.9299 1.1665 96 1.1665C131.07 1.1665 159.5 29.5964 159.5 64.6665V75.3332Z" />
                                <circle r="3" fill="#F75906">
                                    <animateMotion dur="10s" repeatCount="indefinite">
                                        <mpath href="#lockPath" />
                                    </animateMotion>
                                </circle>
                                <path stroke="#FF0000" strokeWidth="3" fill="none" strokeDasharray="1 999" strokeDashoffset="1">
                                    <animate attributeName="stroke-dashoffset" from="1" to="-999" dur="7s" repeatCount="indefinite" />
                                </path>
                            </svg>
                        </div>
                        <div className='z-30 sm:hidden block'>
                            <svg width="134" height="160" viewBox="-1 -1 195 230" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="lockPath" d="M159.5 75.3332V75.8332H160H181.333C186.948 75.8332 191.5 80.385 191.5 85.9998V214C191.5 219.615 186.948 224.167 181.333 224.167H10.6667C5.05182 224.167 0.5 219.615 0.5 214V85.9998C0.5 80.385 5.05182 75.8332 10.6667 75.8332H32H32.5V75.3332V64.6665C32.5 29.5964 60.9299 1.1665 96 1.1665C131.07 1.1665 159.5 29.5964 159.5 64.6665V75.3332Z" />
                                <circle r="3" fill="#F75906">
                                    <animateMotion dur="10s" repeatCount="indefinite">
                                        <mpath href="#lockPath" />
                                    </animateMotion>
                                </circle>
                                <path stroke="#FF0000" strokeWidth="3" fill="none" strokeDasharray="1 999" strokeDashoffset="1">
                                    <animate attributeName="stroke-dashoffset" from="1" to="-999" dur="7s" repeatCount="indefinite" />
                                </path>
                            </svg>
                        </div>
                        <div className='absolute -z-10 w-[1092px] sm:block hidden top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                            <img src={DotsPattern} alt='dots pattern' />
                        </div>
                        <div className='absolute -z-10 w-[1092px] sm:hidden block top-1/3 left-[120%] -translate-x-1/2 -translate-y-1/2'>
                            <img src={DotsPattern} width='900' alt='dots pattern' />
                        </div>
                        <div className='absolute lock-filter -z-10 -top-1/4 -left-1/3'></div>
                    </div>
                </div>
            </div>
            {openModal && <AccessModal handleClose={handleClose} />}
        </section>
    )
}

export default Security;
