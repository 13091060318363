import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { worksData } from '../config/const';
import ProgressBar from '../component/common/process';
import { useSpring, animated } from '@react-spring/web';
import Bubble from '../component/common/bubble';

const Works = () => {
  const { t, i18n } = useTranslation();
  const [scroll, setScroll] = useState(0)
  const box = useRef(null)

  const [{ x, y }, set] = useSpring(() => ({
    x: 0,
    y: 0,
    config: { mass: 100, tension: 650, friction: 150 },
  }));

  const handleScroll = (e) => {
    const position = window.scrollY;
    const x = window.outerWidth - position / 2;
    const y = window.outerHeight - position;
    set({ x, y });
    if(box.current) {
      setScroll(position - box.current.offsetTop);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scroll]);

  return (
    <section id='how-solar-card-works' className='md:px-20 px-4 md:mt-[200px] mt-[80px] relative' ref={box}>
      <div className='sticky top-0 flex flex-col gap-6 pb-4 text-center z-20 bg-[#090B0A]'>
        <h2>{t("works-title")}</h2>
        <h6>{t("works-description")}</h6>
      </div>
      <ProgressBar steps={3} scrollYProgress={scroll} />
      <div className='block relative'>
        <animated.div
          className='absolute -left-1/3 top-0'
          style={{
            transform: x.to((x) => `translate3d(${x / 3}px, ${-y.get() / 3}px, 0)`),
          }}
        >
          <Bubble width='184px' height='184px' />
        </animated.div>
        <animated.div
          className='absolute left-0 top-[1500px]'
          style={{
            transform: x.to((x) => `translate3d(${x / 6}px, ${-y.get() / 3}px, 0)`),
          }}
        >
          <Bubble width='84px' height='84px' />
        </animated.div>
        <animated.div
          className='absolute -left-[200px] top-[3000px]'
          style={{
            transform: x.to((x) => `translate3d(${x / 6}px, ${-y.get() / 3}px, 0)`),
          }}
        >
          <Bubble width='84px' height='84px' />
        </animated.div>
        {
          worksData.map((data, index) =>
            <div className={`md:h-[70vh] h-[155px] flex`} key={`works-${index}`} >
              <div className='w-1/2 pr-10 pt-10 md:block hidden'>
                <div className='h-[600px]'>
                  <div className='sticky top-[300px] works-text lg:text-[160px] text-[120px]'>
                    {t(data.type)}
                  </div>
                </div>
              </div>
              <div className='md:w-1/2 w-full pl-[70px] flex items-center'>
                <div className='flex flex-col md:gap-6 gap-4'>
                  <div className='text-custom-orange text-[12px] font-[700] line-normal uppercase'>
                    {t(data.label)}
                  </div>
                  <div className='flex flex-col items-start md:gap-4 gap-2 self-stretch'>
                    <h3>{t(data.title)}</h3>
                    <h6>{t(data.description)}</h6>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </section>
  )
}

export default Works