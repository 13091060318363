import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_TYMPT_DEV_URL : process.env.REACT_APP_TYMPT_PRO_URL

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});

axiosInstance.interceptors.request.use(options => options);

axiosInstance.interceptors.response.use(response => response);

export default axiosInstance;
