import React from 'react'
import { aboutData } from '../config/const'
import { useTranslation } from 'react-i18next'
import '../styles/custom/about.css'

const usePointerGlow = () => {
    const [status, setStatus] = React.useState(null)
    React.useEffect(() => {
        const syncPointer = ({ x: pointerX, y: pointerY }) => {
            const x = pointerX.toFixed(2)
            const y = pointerY.toFixed(2)
            const xp = (pointerX / window.innerWidth).toFixed(2)
            const yp = (pointerY / window.innerHeight).toFixed(2)
            document.documentElement.style.setProperty('--x', x)
            document.documentElement.style.setProperty('--xp', xp)
            document.documentElement.style.setProperty('--y', y)
            document.documentElement.style.setProperty('--yp', yp)
            setStatus({ x, y, xp, yp })
        }
        document.body.addEventListener('pointermove', syncPointer)
        return () => {
            document.body.removeEventListener('pointermove', syncPointer)
        }
    }, [])
    return [status]
}

const AboutUs = () => {
    const { t, i18n } = useTranslation();
    const [status] = usePointerGlow();

    return (
        <section id='aboutus' className='2xl:px-20 px-4 flex flex-col lg:gap-[104px] gap-[24px] items-center lg:mt-[200px] mt-[50px] bg-[#090B0A]'>
            <div className='flex flex-col gap-6 text-center'>
                <div className='text-white text-center lg:text-[40px] text-[32px] font-[700] leading-[100%]'>
                    {t("about-title")}
                </div>
                <div className='heading-description text-[16px]'>
                    {t("about-description")}
                </div>
            </div>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4 bg-[#090B0A] relative'>
                {aboutData.map(({ icon, label, text, background, width }, index) =>
                    <div className='card-container' key={label}>
                        <div className='card relative lg:w-[416px] lg:h-[319px] w-[343px] h-[214px]'>
                            <div className='lg:px-8 px-6 py-8 w-full h-full' data-glow>
                                <div className='absolute z-10 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2'>
                                    <img src={background} alt={`${label}`} />
                                </div>
                                <div className='flex lg:flex-col flex-row items-start lg:gap-10 gap-4'>
                                    <div className='icon-button'>
                                        <img src={icon} alt={`card-icon-${index}`} />
                                    </div>
                                    <div className={`lg:w-[170px] w-[130px] lg:text-[24px] text-[18px] leading-[120%] font-[700] text-white`}>
                                        {t(label)}
                                    </div>
                                </div>
                                <div className='mt-6 heading-description lg:text-[16px] text-[14px]'>
                                    {t(text)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default AboutUs
