import React, { useRef, useState } from 'react'
import { useSpring, animated } from '@react-spring/web';
import Card from '../assets/image/card.png'
import { Button } from '../component/common/button'
import Comment from '../component/page/hero/comment'
import { useTranslation } from 'react-i18next'
import Bubble from '../component/common/bubble';
import AccessModal from '../component/page/access/AccessModal'

const HeroSection = () => {
    const { t, i18n } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const parallaxRef = useRef({ x: 0, y: 0 });

    const [{ x, y }, set] = useSpring(() => ({
        x: 0,
        y: 0,
        config: { mass: 100, tension: 650, friction: 150 },
    }));

    const handleMouseMove = (e) => {
        const x = e.clientX - window.innerWidth / 2;
        const y = e.clientY - window.innerHeight;
        set({ x, y });
    };

    const handleOpen = () => {
        setOpenModal(true);
        document.body.style.overflow = 'hidden';
    }

    const handleClose = () => {
        setOpenModal(false);
        document.body.style.overflow = 'unset';
    }

    return (
        <section id='hero' className='hero-section lg:px-20 md:px-10 px-4 flex xl:flex-row flex-col items-center xl:justify-between justify-start xl:pt-[120px] pt-[200px] xl:h-[850px] md:h-[1100px] h-[900px] overflow-hidden relative' onMouseMove={handleMouseMove}>
            <div className='md:w-[600px] w-auto flex flex-col items-start gap-[56px]'>
                <div className='flex flex-col gap-[24px]'>
                    <div className='font-[Nunito] lg:text-[72px] md:text-[55px] text-[40px] font-[700] leading-[100%]'>
                        <h1 className='text-custom-orange w-full relative'>
                            {t("hero-title-orange")}
                            <div className='absolute md:-right-20 md:-top-10 -top-14'>
                                <Comment text={t("hero-comment")} />
                            </div>
                        </h1>
                        <h1 className='text-white'>
                            {t("hero-title-white")}
                        </h1>
                    </div>
                    <div className='self-stretch heading-description md:w-[550px] w-auto'>
                        <h6>{t("hero-description")}</h6>
                    </div>
                </div>
                <Button className='button-primary z-10' onClick={handleOpen}>
                    {t("hero-access-btn")}
                </Button>
            </div>
            <div className='md:block hidden relative -mt-52'>
                <div className='origin-[100%_70%] -rotate-[21deg] -skew-x-[20deg] -skew-y-4 translate-y-96 translate-x-10 w-[348px] h-[212px]'>
                    <img src={Card} alt='card' />
                </div>
                <div className='origin-[100%_100%] -rotate-[14deg] -skew-x-[30deg] -skew-y-3 translate-y-32 w-[348px] h-[212px]'>
                    <img src={Card} alt='card' />
                </div>
                <div className='origin-[100%_80%] rotate-0 -skew-x-[30deg] -skew-y-3 -translate-y-36 w-[348px] h-[212px]'>
                    <img src={Card} alt='card' />
                </div>
            </div>
            <div className='md:hidden block relative mr-12 -mt-24'>
                <div className='origin-[100%_70%] -rotate-[24deg] -skew-x-[20deg] -skew-y-4 translate-y-48 translate-x-10 w-[232px] h-[142px]'>
                    <img src={Card} alt='card' />
                </div>
                <div className='origin-[100%_100%] -rotate-[14deg] -skew-x-[31deg] -skew-y-3 translate-y-8 w-[232px] h-[142px]'>
                    <img src={Card} alt='card' />
                </div>
                <div className='origin-[100%_80%] rotate-0 -skew-x-[30deg] -skew-y-3 -translate-y-36 w-[232px] h-[142px]'>
                    <img src={Card} alt='card' />
                </div>
            </div>
            <div className='md:block hidden'> 
                <animated.div
                    className='absolute right-[92px] top-[380px]'
                    style={{
                        transform: x.to((x) => `translate3d(${x / 3}px, ${y.get() / 3}px, 0)`),
                    }}
                >
                    <Bubble width='184px' height='184px' />
                </animated.div>
                <animated.div
                    className='absolute -right-[89px] top-[580px]'
                    style={{
                        transform: x.to((x) => `translate3d(${x / 4}px, ${y.get() / 4}px, 0)`),
                    }}
                >
                    <Bubble width='146px' height='146px' />
                </animated.div>
                <animated.div
                    className='absolute right-[127px] top-[680px]'
                    style={{
                        transform: x.to((x) => `translate3d(${x / 2}px, ${y.get() / 2}px, 0)`),
                    }}
                >
                    <Bubble width='90px' height='90px' />
                </animated.div>
            </div>
            <div className='md:hidden block'>
                <animated.div
                    className='absolute -right-10 bottom-[15%]'
                    style={{
                        transform: x.to((x) => `translate3d(${x / 3}px, ${y.get() / 3}px, 0)`),
                    }}
                >
                    <Bubble width='122px' height='122px' />
                </animated.div>
                <animated.div
                    className='absolute right-0 bottom-[5%]'
                    style={{
                        transform: x.to((x) => `translate3d(${x / 4}px, ${y.get() / 4}px, 0)`),
                    }}
                >
                    <Bubble width='60px' height='60px' />
                </animated.div>
            </div>
            {openModal && <AccessModal handleClose={handleClose}/>}
        </section>
    )
}

export default HeroSection
