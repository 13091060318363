import React, { useState } from 'react'
import { Button, SmallButton } from '../component/common/button'
import { TopLeftBorderIcon, TopRightBorderIcon, BottomLeftBorderIcon, BottomRightBorderIcon } from '../assets/shape/border'
import { useTranslation } from 'react-i18next'
import '../styles/custom/access.css'
import AccessModal from '../component/page/access/AccessModal'

const Access = () => {
    const [openModal, setOpenModal] = useState(false);
    const { t, i18n } = useTranslation();

    const handleOpen = () => {
        setOpenModal(true);
        document.body.style.overflow = 'hidden';
    }

    const handleClose = () => {
        setOpenModal(false);
        document.body.style.overflow = 'unset';
    }

    return (
        <section className='w-full relative overflow-hidden'>
            <div className='access-bubble absolute top-0 md:-left-[113px] -left-[17px] md:w-[184px] md:h-[184px] w-[40px] h-[40px]'></div>
            <div className='access-bubble absolute bottom-0 md:-right-[198px] -right-[21px] md:w-[294px] md:h-[294px] w-[80px] h-[80px]'></div>
            <div className='md:w-[640px] w-auto md:py-[186px] py-20 mx-auto px-4'>
                <div className='relative flex flex-col md:gap-14 gap-10 items-center justify-center md:px-8 px-auto px-1 py-6'>
                    <div className='flex flex-col gap-8 items-center justify-center'>
                        <SmallButton>{t("access-begin")}</SmallButton>
                        <h2>{t("access-title")}</h2>
                        <h6 className='text-center max-md:w-[80%]'>{t("access-description")}</h6>
                    </div>
                    <Button className='button-primary' onClick={handleOpen}>
                        {t("access-button")}
                    </Button>
                    <div className='absolute top-0 left-0'>
                        <TopLeftBorderIcon />
                    </div>
                    <div className='absolute top-0 right-0'>
                        <TopRightBorderIcon />
                    </div>
                    <div className='absolute bottom-0 left-0'>
                        <BottomLeftBorderIcon />
                    </div>
                    <div className='absolute bottom-0 right-0'>
                        <BottomRightBorderIcon />
                    </div>
                </div>
            </div>
            {openModal && <AccessModal handleClose={handleClose}/>}
        </section>
    )
}

export default Access
