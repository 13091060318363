import React from 'react'
import { SmallButton } from '../component/common/button'
import { useTranslation } from 'react-i18next'
import { cryptoData } from '../config/const'

const Crypto = () => {
    const { t, i18n } = useTranslation();
    return (
        <section id='crypto' className='crypto-background md:mx-20 mx-4 md:mt-[200px] mt-20 md:py-[96px] md:px-[64px] py-[32px] px-6 flex flex-col justify-between lg:gap-[144px] md:gap-[90px] gap-[48px]'>
            <div className='flex flex-col gap-6 items-center'>
                <SmallButton>{t("crypto-sm-btn")}</SmallButton>
                <h2>{t("crypto-title")}</h2>
                <h6 className='text-center'>{t("crypto-description")}</h6>
            </div>
            <div className='flex lg:flex-row flex-col justify-between sm:items-start items-center gap-16'>
                {cryptoData.map((data, index) =>
                    <div className='flex flex-col items-start md:gap-[40px] gap-6 lg:w-[320px] w-auto' key={`crypto-card-${index}`}>
                        <div className='icon-button md:w-[56px] md:h-[56px] w-[48px] h-[48px]'>
                            <img src={data.icon} alt={data.title} />
                        </div>
                        <div className='flex flex-col items-start md:gap-6 gap-4 self-stretch'>
                            <h3>{t(data.title)}</h3>
                            <p><span className='opacity-60 text-[16px]'>{t(data.description)}</span></p>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default Crypto
