import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './view';
import './styles/global.css'
import './styles/typography.css'
import Header from './view/header';
import Footer from './view/footer';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
